<template>
  <footer id="ft">
    <div class="disclosures">
      <div class="container">
        <h3>DISCLOSURES:</h3>
        <p>
          These indexes were created and developed by Closed-End Fund Advisors,
          Inc. CEF Advisors launched these indexes in January 2017 based on
          December 31, 2016 data and began pricing on January 1, 2019. CEF
          Advisors does not guarantee the accuracy or the completeness of the
          index or any data included therein and CEF Advisors shall have no
          liability for any errors, omissions or interruptions therein. CEF
          Advisors makes no warranty, express or implied, as to results to be
          obtained by any person or entity from the use of the index or any data
          included therein. CEF Advisors makes no express or implied warranties,
          and expressly disclaims all warranties of merchantability or fitness
          for a particular purpose or use with respect to the index or any data
          included therein. Without limiting any of the foregoing, in no event
          shall CEF Advisors have any liability for any special, punitive,
          direct, indirect or consequential damages (including lost profits)
          arising out of matters relating to the use of the index, even if
          notified of the possibility of such damages.
        </p>
        <p>
          The historical values above have not been verified by an independent
          third party. The historical performance data is for illustrative
          purposes only and does not represent the actual performance of any
          investment product. No representation is being made that any
          investment will achieve performance similar to that shown. The index
          does not charge management fees or brokerage expenses, and no such
          fees or expenses were deducted from the historical performance shown.
          The index is unmanaged and an investor cannot invest directly in the
          index.
        </p>
        <p>
          Performance information for the index is for illustrative purposes
          only and does not represent the performance of any actual investment.
          The historical performance of the price and NAV of the index should
          not be taken as an indication of future performance, and no assurance
          can be given as to the index's value on any date. The data for the
          historical performance of the index was calculated on materially the
          same basis on which the performance of the index is now calculated.
          Discounts are the result of a closed-end fund's market price deviating
          from its Net Asset Value (NAV).
        </p>
        <p>
          In case any constituents of the index experience a liquidation,
          merger, open-ending or are no longer a closed-end fund, we will remove
          them as of their last day of existence. For mergers, if the merged
          fund is in the same sub-grouping inside CEFdata.com, then we will
          replace the merged fund with it's replacement in equal dollar terms.
          If a fund is liquidated, open-ended, etc, we will remove the fund and
          its allocation from the index. In this case, for example. If a fund
          was 2% of the index then future pricing would assume a full allocation
          was 98%. The 2% is adsorbed on a pro-rata basis by all surviving
          funds.
        </p>
        <p>
          &copy; Closed-End Fund Advisors Inc. (CEFadvisors) and
          <a href="https://cefdata.com/">CEFdata.com</a> 2024. All rights
          reserved. Any copying, republication or redistribution of CEFadvisors,
          CEFdata.com, content, including by caching, framing or similar means,
          is expressly prohibited without the prior written consent of
          CEFAdvisors. CEFAdvisors shall not be liable for any errors or delays
          in the content, or for any actions taken in reliance thereon.
        </p>
      </div>
    </div>
    <!-- /.disclosures -->
    <div class="container">
      <div class="row mb-5">
        <div class="col-xs-12 col-lg-6">
          <h3>
            Find Out How CEF Advisors Can Help You Get The Most Out Of CEF
            Investing.
          </h3>
        </div>
        <!-- /.col -->
        <div class="col-xs-12 col-lg-6">
          <form>
            <div>
              <label class="visually-hidden" for="ft-form-name">Name</label>
              <input
                type="text"
                id="ft-form-name"
                class="form-control"
                name="name"
                value=""
                placeholder="Name"
              />
            </div>
            <div>
              <label class="visually-hidden" for="ft-form-email">Email</label>
              <input
                type="email"
                id="ft-form-email"
                class="form-control"
                name="email"
                value=""
                placeholder="Email"
              />
            </div>
            <div></div>
            <div>
              <button type="submit" class="btn btn-info">
                Schedule A 15 Minute Call
              </button>
            </div>
          </form>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
      <div class="row pt-2">
        <div class="info col-xs-12 col-lg-6">
          <div>
            <div class="logos">
              <img
                src="@/assets/img/cefa-logo-inverse.png"
                class="logo"
                alt="CEF Advisors"
              />
              <div>
                <a href="#"
                  ><img src="@/assets/img/icon-linkedin.png" alt="LinkedIn"
                /></a>
                <a href="https://aicalliance.org/"
                  ><img src="@/assets/img/icon-aica.png" alt="AICA"
                /></a>
                <a href="https://aicalliance.org/alliance-content/pod-cast/"
                  ><img src="@/assets/img/icon-navigator.png" alt="Navigator"
                /></a>
              </div>
            </div>
            <!-- /.logos -->
            <div class="contact">
              <div>
                <a href="tel:8042882482">804-288-2482</a><br />
                Toll Free <a href="tel:8003563508">800-356-3508</a>
              </div>
              <div>
                7204 Glen Forest Drive<br />
                Richmond VA 23219
              </div>
            </div>
            <!-- /.contact -->
          </div>
        </div>
        <!-- /.col -->
        <div class="links col-xs-12 col-lg-6">
          <ul>
            <li>
              <h5>ABOUT</h5>
              <div><a href="#">WHY US</a></div>
              <div><a href="#">TEAM</a></div>
              <div><a href="#">PRESS</a></div>
            </li>
            <li>
              <h5>INVESTING</h5>
              <div><a href="#">FUND MODELS</a></div>
              <div><a href="#">INVESTMENT MANAGEMENT</a></div>
              <div><a href="#">UIT</a></div>
              <div><a href="#">CEF PR ARCHIVE</a></div>
            </li>
            <li>
              <h5>DATA</h5>
              <div><a href="#">DATA SERVICE</a></div>
              <div><a href="#">INDEXES</a></div>
              <div><a href="#">CEFs</a></div>
              <div><a href="#">BDCs</a></div>
            </li>
            <li class="highlight">
              <h5><a href="#">RESOURCES</a></h5>
              <div><a href="#">LOGIN</a></div>
              <div><a href="#">CONTACT</a></div>
              <div><a href="#">LEGAL</a></div>
              <div><a href="#">FORM ADV 2</a></div>
              <div><a href="#">FORM CRS</a></div>
            </li>
            <!-- /.highlight -->
          </ul>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container -->
  </footer>
  <!-- /#ft -->
</template>

<script setup></script>

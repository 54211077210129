import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// Create a local toast instance

export function handleApiError(error, message = null) {
  // Define the handleGlobally method on the error object
  // Log the error to a logging service (e.g., Sentry, LogRocket)
  // logErrorToService(this); // Assuming `this` is the error object
  // Show a global notification
  // const toast = createToastInterface();
  // toast.error(`An error occurred: ${error.message}`);
  // Optionally send error information to an API
  // sendErrorToApi(this);
  // console.log()

  const toast = useToast();
  toast.error(message ? message : error.message);
}

<template>
  <div class="table-responsive">
    <table class="table sidebar">
      <thead>
        <tr>
          <th colspan="2">Fund Info</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>{{ tableData.inception.label }}</th>
          <td>{{ tableData.inception.value }}</td>
        </tr>
        <tr>
          <th>{{ tableData.splitAdjustIpoNavMktPrice.label }}</th>
          <td>{{ tableData.splitAdjustIpoNavMktPrice.value }}</td>
        </tr>
        <tr>
          <th>{{ tableData.cumulativeDistributions.label }}</th>
          <td>{{ tableData.cumulativeDistributions.value }}</td>
        </tr>
        <tr>
          <th>{{ tableData.sponsor.label }}</th>
          <td>{{ tableData.sponsor.label }}</td>
        </tr>
        <tr>
          <th>{{ tableData.website.label }}</th>
          <td>
            <a
              v-if="tableData.website.value.domain"
              :href="tableData.website.value.url"
              target="_blank"
              >{{ tableData.website.value.domain }}</a
            >
          </td>
        </tr>
        <tr>
          <th>{{ tableData.navSymbol.label }}</th>
          <td>{{ tableData.navSymbol.value }}</td>
        </tr>
        <tr>
          <th>{{ tableData.incorporated.label }}</th>
          <td>{{ tableData.incorporated.value }}</td>
        </tr>
        <tr>
          <th>{{ tableData.staggeredBoard.label }}</th>
          <td>{{ tableData.staggeredBoard.value }}</td>
        </tr>
        <tr>
          <th>{{ tableData.termDate.label }}</th>
          <td>{{ tableData.termDate.value }}</td>
        </tr>
        <tr>
          <th>{{ tableData.objective.label }}</th>
          <td>
            {{ tableData.objective.value }}
          </td>
        </tr>
        <tr>
          <th>{{ tableData.portfolioManagers.label }}</th>
          <td>
            <span
              v-for="(item, index) in tableData.portfolioManagers.value"
              :key="`item_${index}`"
              >{{ item }}<br
            /></span>
          </td>
        </tr>
        <tr>
          <th>{{ tableData.fundListed.label }}</th>
          <td>{{ tableData.fundListed.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue';
import { formatDateToString, getDomain } from '@/services/utils';

const props = defineProps({
  shareClass: {
    type: Object,
    default: () => ({}), // Default value as an empty object
  },
});

const tableData = computed(() => {
  const { shareClass } = props;

  return {
    inception: {
      label: 'Inception',
      value: formatDateToString(shareClass.inception_date),
    },
    splitAdjustIpoNavMktPrice: {
      label: 'Split Adjust IPO NAV/Mkt Price',
      value: '$XX/$XX',
    },
    cumulativeDistributions: {
      label: 'Cumulative Distributions',
      value: '$XX',
    },
    sponsor: {
      label: 'Sponsor',
      value: shareClass.fund?.sponsor_full_name,
    },
    website: {
      label: 'Website',
      value: shareClass.fund?.website?.includes('http')
        ? {
            url: shareClass.fund.website,
            domain: getDomain(shareClass.fund.website),
          }
        : { url: '#', domain: null },
    },
    navSymbol: {
      label: 'NAV Symbol',
      value: shareClass.nav_ticker,
    },
    incorporated: {
      label: 'Incorporated',
      value: shareClass.fund?.incorporated_state,
    },
    staggeredBoard: {
      label: 'Staggered Board',
      value: shareClass.fund?.staggered_board ? 'Yes' : 'No',
    },
    termDate: {
      label: 'Term Date',
      value: shareClass?.fund?.target_date
        ? formatDateToString(shareClass.fund.target_date)
        : 'N/A',
    },
    objective: {
      label: 'Objective',
      value: shareClass.fund?.investment_objective || '-',
    },
    portfolioManagers: {
      label: 'Portfolio Manager(s)',
      value: [
        'Name A. Name',
        'Name A. Name 2',
        'Name A. Name 3',
        'Name A. Name 4',
      ],
    },
    fundListed: {
      label: 'Fund Listed',
      value: shareClass.fund?.get_exchange_display,
    },
  };
});
</script>

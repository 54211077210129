<template>
  <!-- Earnings -->
  <div class="table-responsive">
    <table class="table sidebar">
      <thead>
        <tr>
          <th colspan="2">Earnings</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in earningsAndUniiData" :key="`item_${index}`">
          <th>
            {{ item.label }}
            <span v-if="item.subLabel">({{ item.subLabel }})</span>
          </th>
          <td>{{ item.value || 0 }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- /.table-responsive -->
</template>
<script setup>
import { computed } from 'vue';
import { formatDateToUSA } from '@/services/utils';

// Define props
const props = defineProps({
  shareClassData: {
    type: Object,
    default: () => ({}),
  },
  shareClass: {
    type: Object,
    default: () => ({}),
  },
});

const earningsAndUniiData = computed(() => {
  const { shareClass, shareClassData } = props;

  if (!shareClass?.fund?.is_fund_type_dbc) {
    return {
      earningsPerShare: {
        label: 'Earnings / Share',
        subLabel: formatDateToUSA(shareClassData?.earnings_share?.date) || '',
        value: `$${shareClassData?.earnings_share?.data_value || '-'}`,
      },
      earnCoverage: {
        label: 'Earn Coverage',
        value: `${shareClassData?.earnings_coverage?.data_value || '-'}%`,
      },
      earningsTrend: {
        label: 'Earnings Trend',
        value: shareClassData?.earnings_trend?.data_value || '-',
      },
      uniiPerShare: {
        label: 'UNII / Share',
        subLabel: formatDateToUSA(shareClassData?.unii_share?.date) || '',
        value: `$${shareClassData?.unii_share?.data_value || '-'}`,
      },
      relativeUnii: {
        label: 'Rel UNII',
        value: shareClassData?.rel_unii?.data_value || '-',
      },
      uniiTrend: {
        label: 'UNII Trend',
        value: shareClassData?.unii_trend?.data_value || '-',
      },
    };
  }

  return {
    earningsPerShare: {
      label: 'Earnings / Share',
      subLabel: formatDateToUSA(shareClassData?.nii?.date) || '',
      value: `$${shareClassData?.nii_share?.data_value || '-'}`,
    },
    adjCoreNiiCoverage: {
      label: 'Adj Core NII Coverage',
      value: `${shareClassData?.nii_coverage_ratio_pct?.data_value || '-'}%`,
    },
    oneYearAvgNiiCoverage: {
      label: '1 Yr Avg NII Coverage',
      value: `${shareClassData?.nii_coverage_avg_1yr?.data_value || '-'}%`,
    },
    oneYearAvgAdjCoreNiiCoverage: {
      label: '1 Yr Avg Adj Core NII Coverage',
      value: `${shareClassData?.core_nii_coverage_avg_1yr?.data_value || '-'}%`,
    },
    niiTrend: {
      label: 'NII Trend',
      value: `${shareClassData?.nii_trend?.data_value || '-'}%`,
    },
    adjCoreNiiTrend: {
      label: 'Adj Core NII Trend',
      value: `${shareClassData?.core_nii_trend?.data_value || '-'}%`,
    },
  };
});
</script>

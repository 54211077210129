<template>
  <LoadingOverlay :loading="loading">
    <div>
      <LineChart :tabs="tabs" yAxisFormat="percent" type="bar" />
    </div>
  </LoadingOverlay>
</template>

<script setup>
import LineChart from '@/components/charts/LineChart.vue';
import { formatNumber } from '@/services/utils';
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';
import { computed } from 'vue';

const props = defineProps({
  reinvestedPriceData: {
    type: Object,
    default: () => ({}),
  },
  ticker: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const periods = ['2023', '2022', '2021', '2020', '2019'];
const backgroundColor = ['#21739C', '#4DBFDE', '#57E5AC', '#C766FF', '#f955af'];
const borderColor = ['#21739C', '#4DBFDE', '#57E5AC', '#C766FF', '#f955af'];

const tabs = computed(() => [
  {
    name: 'NAV',
    title: 'Calendar Total Return',
    subTitle: 'With Reinvestment',
    labels: periods,
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Calendar Total Return – With Reinvestment',
        data: periods.map((period) =>
          formatNumber(
            props.reinvestedPriceData?.[`nav_tr_reinvested_${period}`]?.[
              props.ticker
            ],
            2
          )
        ),
        backgroundColor: backgroundColor,
        borderColor: borderColor,
      },
    ],
  },
  {
    name: 'Price',
    title: 'Calendar Total Return',
    subTitle: 'With Reinvestment',
    labels: periods,
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Total Return – With Reinvestment',
        data: periods.map((period) =>
          formatNumber(
            props.reinvestedPriceData?.[`price_tr_reinvested_${period}`]?.[
              props.ticker
            ],
            2
          )
        ),
        backgroundColor: backgroundColor,
        borderColor: borderColor,
      },
    ],
  },
]);
</script>

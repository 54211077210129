// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import { viewsRoutes } from './routes/views';
import { pagesRoutes } from './routes/pages';

const routes = [...viewsRoutes, ...pagesRoutes];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;

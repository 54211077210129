<template v-if="items.length > 0">
  <DonutChart :items="items" :title="title" />
</template>

<script setup>
import DonutChart from '@/components/charts/DonutChart.vue';
import { computed } from 'vue';
import { formatNumber } from '@/services/utils';

const props = defineProps({
  shareClassData: {
    type: Object,
    default: () => ({}),
  },
});

const items = computed(() => {
  const sortedData = Object.entries(
    props.shareClassData?.geographical_exposure?.data_value || {}
  )
    .sort(([, valueA], [, valueB]) => valueB - valueA)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  return Object.entries(sortedData)
    .filter(([_, value]) => value > 0)
    .map(([key, value]) => ({
      value: formatNumber(value, 2),
      label:
        props.shareClassData?.geographical_exposure?.data_type_choices?.[key] ||
        '',
    }));
});

const title = 'GEOGRAPHICAL EXPOSURE';
</script>

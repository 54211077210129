<template>
  <LoadingOverlay :loading="loading">
    <div class="table-responsive">
      <table class="table sidebar">
        <thead>
          <tr>
            <th colspan="2">Universal Average Discount Data</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in tableData" :key="key">
            <th>{{ item.label }}</th>
            <td>{{ item.value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- /.table-responsive -->
  </LoadingOverlay>
</template>

<script setup>
import { computed } from 'vue';
import { formatNumber } from '@/services/utils';
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';

const props = defineProps({
  fundProfileData: {
    type: Object,
    default: () => ({}),
  },
  shareClass: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const tableData = computed(() => {
  const fundData = props.fundProfileData?.universal_average_discount_data || {};

  return {
    all_funds: {
      label: 'Average Premium/(Discount)',
      value: formatNumber(fundData.all_funds || 0, 1) + '%',
    },
    cef_funds: {
      label: 'All Closed-End Funds',
      value: formatNumber(fundData.cef_funds || 0, 1) + '%',
    },
    cef_equity: {
      label: 'Equity Funds',
      value: formatNumber(fundData.cef_equity || 0, 1) + '%',
    },
    cef_taxable_bond: {
      label: 'Taxable Bond Funds',
      value: formatNumber(fundData.cef_taxable_bond || 0, 1) + '%',
    },
    bdc_funds: {
      label: 'Business Development Companies (BDCs)',
      value: formatNumber(fundData.bdc_funds || 0, 1) + '%',
    },
    cef_muni_bond: {
      label: 'Municipal Bond Funds',
      value: formatNumber(fundData.cef_muni_bond || 0, 1) + '%',
    },
    group: {
      label: props.shareClass?.fund?.group || 'Group',
      value: formatNumber(fundData.group || 0, 1) + '%',
    },
  };
});
</script>

<template>
  <DonutChart :items="items" :title="title" />
</template>

<script setup>
import DonutChart from '@/components/charts/DonutChart.vue';

const title = 'Call Schedule';
const items = [
  {
    label: 'Common Stock (US)',
    value: 18,
  },
  {
    label: 'Stock 2 (US)',
    value: 7,
  },
  {
    label: 'Stock 3 (US)',
    value: 9,
  },
  {
    label: 'Stock 4 (US)',
    value: 5,
  },
  {
    label: 'Stock 5 (US)',
    value: 1,
  },
  {
    label: 'Stock 6 (US)',
    value: 4,
  },
  {
    label: 'Stock 7 (US)',
    value: 2,
  },
  {
    label: 'Stock 8 (US)',
    value: 5,
  },
  {
    label: 'Stock 9 (US)',
    value: 11,
  },
  {
    label: 'Stock 10 (US)',
    value: 5,
  },
  {
    label: 'Stock 11 (US)',
    value: 10,
  },
  {
    label: 'Stock 12 (US)',
    value: 15,
  },
  {
    label: 'Stock 13 (US)',
    value: 8,
  },
];
</script>

<template>
  <div>
    <LoadingOverlay :loading="loading">
      <LineChart :tabs="tabs" yAxisFormat="money" />
    </LoadingOverlay>
  </div>
</template>

<script setup>
import LineChart from '@/components/charts/LineChart.vue';
import { dateToMonthName, dateToQuarter } from '@/services/utils';
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';
import { computed } from 'vue';

const props = defineProps({
  growthData: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const tabs = computed(() => [
  {
    name: '1 Mo',
    title: 'Growth of $10,000',
    labels:
      props.growthData.one_month?.date?.map(
        (_, index) => `Week ${index + 1}`
      ) || [],
    yAxisFormat: 'money',
    datasets: [
      {
        label: 'Price',
        data: props.growthData.one_month?.price?.map((x) => x) || [],
      },
      {
        label: 'NAV',
        data: props.growthData.one_month?.nav?.map((x) => x) || [],
      },
      {
        label: 'Peer Price',
        data: props.growthData.one_month?.price_peer?.map((x) => x) || [],
      },
      {
        label: 'Peer NAV',
        data: props.growthData.one_month?.nav_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '3 Mo',
    title: 'Growth of $10,000',
    labels: props.growthData.three_month?.date?.map(dateToMonthName) || [],
    yAxisFormat: 'money',
    datasets: [
      {
        label: 'Price',
        data: props.growthData.three_month?.price?.map((x) => x) || [],
      },
      {
        label: 'NAV',
        data: props.growthData.three_month?.nav?.map((x) => x) || [],
      },
      {
        label: 'Peer Price',
        data: props.growthData.three_month?.price_peer?.map((x) => x) || [],
      },
      {
        label: 'Peer NAV',
        data: props.growthData.three_month?.nav_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: 'YTD',
    title: 'Growth of $10,000',
    labels: props.growthData.ytd?.date?.map(dateToQuarter) || [],
    yAxisFormat: 'money',
    datasets: [
      {
        label: 'Price',
        data: props.growthData.ytd?.price?.map((x) => x) || [],
      },
      { label: 'NAV', data: props.growthData.ytd?.nav?.map((x) => x) || [] },
      {
        label: 'Peer Price',
        data: props.growthData.ytd?.price_peer?.map((x) => x) || [],
      },
      {
        label: 'Peer NAV',
        data: props.growthData.ytd?.nav_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '1 Year',
    title: 'Growth of $10,000',
    labels: props.growthData.one_year?.date?.map(dateToQuarter) || [],
    yAxisFormat: 'money',
    datasets: [
      {
        label: 'Price',
        data: props.growthData.one_year?.price?.map((x) => x) || [],
      },
      {
        label: 'NAV',
        data: props.growthData.one_year?.nav?.map((x) => x) || [],
      },
      {
        label: 'Peer Price',
        data: props.growthData.one_year?.price_peer?.map((x) => x) || [],
      },
      {
        label: 'Peer NAV',
        data: props.growthData.one_year?.nav_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '3 Year',
    title: 'Growth of $10,000',
    labels: props.growthData.three_year?.date?.map(dateToQuarter) || [],
    yAxisFormat: 'money',
    datasets: [
      {
        label: 'Price',
        data: props.growthData.three_year?.price?.map((x) => x) || [],
      },
      {
        label: 'NAV',
        data: props.growthData.three_year?.nav?.map((x) => x) || [],
      },
      {
        label: 'Peer Price',
        data: props.growthData.three_year?.price_peer?.map((x) => x) || [],
      },
      {
        label: 'Peer NAV',
        data: props.growthData.three_year?.nav_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '5 Year',
    title: 'Growth of $10,000',
    labels: props.growthData.five_year?.date?.map(dateToQuarter) || [],
    yAxisFormat: 'money',
    datasets: [
      {
        label: 'Price',
        data: props.growthData.five_year?.price?.map((x) => x) || [],
      },
      {
        label: 'NAV',
        data: props.growthData.five_year?.nav?.map((x) => x) || [],
      },
      {
        label: 'Peer Price',
        data: props.growthData.five_year?.price_peer?.map((x) => x) || [],
      },
      {
        label: 'Peer NAV',
        data: props.growthData.five_year?.nav_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '10 Year',
    title: 'Growth of $10,000',
    labels: props.growthData.ten_year?.date?.map(dateToQuarter) || [],
    yAxisFormat: 'money',
    datasets: [
      {
        label: 'Price',
        data: props.growthData.ten_year?.price?.map((x) => x) || [],
      },
      {
        label: 'NAV',
        data: props.growthData.ten_year?.nav?.map((x) => x) || [],
      },
      {
        label: 'Peer Price',
        data: props.growthData.ten_year?.price_peer?.map((x) => x) || [],
      },
      {
        label: 'Peer NAV',
        data: props.growthData.ten_year?.nav_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '15 Year',
    title: 'Growth of $10,000',
    labels: props.growthData.fifteen_year?.date?.map(dateToQuarter) || [],
    yAxisFormat: 'money',
    datasets: [
      {
        label: 'Price',
        data: props.growthData.fifteen_year?.price?.map((x) => x) || [],
      },
      {
        label: 'NAV',
        data: props.growthData.fifteen_year?.nav?.map((x) => x) || [],
      },
      {
        label: 'Peer Price',
        data: props.growthData.fifteen_year?.price_peer?.map((x) => x) || [],
      },
      {
        label: 'Peer NAV',
        data: props.growthData.fifteen_year?.nav_peer?.map((x) => x) || [],
      },
    ],
  },
]);
</script>

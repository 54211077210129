<template>
  <section class="component donut-chart multi is-visible" v-if="items.length">
    <h2>{{ title }}</h2>
    <div class="chart-wrapper">
      <canvas ref="chartCanvas"></canvas>
      <div :class="['overlay', theme]">
        <div
          v-for="(item, index) in items"
          :key="index"
          :data-index="index"
          :style="getStyle(index)"
          :class="getClass(index)"
        >
          <h3 v-if="index !== 0"></h3>
          <h5>{{ item.label || `Item ${index + 1}` }}</h5>
          <h2>{{ item.value || 1 }}%</h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export default {
  props: {
    title: { type: String, default: 'My Chart' },
    items: { type: Array, required: true, default: () => [] },
    theme: { type: String, default: 'blue' },
    colors: {
      type: Array,
      default: () => [
        '#21739E',
        '#4FBFDE',
        '#C7E5F2',
        '#f955af',
        '#4FDEA6',
        '#C4FFE5',
        '#FC7D4F',
        '#FFA161',
        '#FFC7A1',
        '#D11280',
        '#FF5799',
        '#FFABC2',
        '#000000',
      ],
    },
  },
  setup(props) {
    const chartCanvas = ref(null);
    const chartInstance = ref(null);
    const activeIndex = ref(null);

    const lightOrDark = (color) => {
      let r, g, b, hsp;

      if (color.match(/^rgb/)) {
        const rgba = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );
        if (rgba) {
          r = parseInt(rgba[1], 10);
          g = parseInt(rgba[2], 10);
          b = parseInt(rgba[3], 10);
        }
      } else {
        const hex = parseInt(color.slice(1), 16);
        r = (hex >> 16) & 255;
        g = (hex >> 8) & 255;
        b = hex & 255;
      }

      hsp = Math.sqrt(0.299 * r ** 2 + 0.587 * g ** 2 + 0.114 * b ** 2);

      return hsp > 127.5 ? 'light' : 'dark';
    };

    const getStyle = (index) => {
      if (index !== 0) {
        return { backgroundColor: props.colors[index % props.colors.length] };
      }
      return {};
    };

    const getClass = (index) => {
      const color = props.colors[index % props.colors.length];
      const lightOrDarkClass = lightOrDark(color);

      return [
        { segment: index !== 0, show: index === activeIndex.value },
        lightOrDarkClass,
      ];
    };

    const initializeChart = () => {
      if (!props.items || props.items.length === 0) {
        console.error('No data available for the donut chart.');
        return;
      }

      const labels = props.items.map(
        (item, i) => item.label || `Item ${i + 1}`
      );
      const data = props.items.map((item) => item.value || 1);
      const backgroundColor = props.items.map(
        (_, i) => props.colors[i % props.colors.length]
      );

      const hoverPlugin = {
        id: 'hoverPlugin',
        beforeEvent(chart, args) {
          const event = args.event;

          if (event.type === 'mousemove') {
            const activeElements = chart.getActiveElements();

            activeIndex.value =
              activeElements.length > 0 ? activeElements[0].index : null;
          }
        },
      };

      chartInstance.value = new Chart(chartCanvas.value, {
        type: 'doughnut',
        plugins: [hoverPlugin],
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        },
        data: {
          labels,
          datasets: [
            {
              label: props.title,
              data,
              backgroundColor,
              borderWidth: 0,
            },
          ],
        },
      });
    };

    onMounted(() => {
      initializeChart();
    });

    onUnmounted(() => {
      if (chartInstance.value) {
        chartInstance.value.destroy();
      }
    });

    return {
      chartCanvas,
      activeIndex,
      getClass,
      getStyle,
    };
  },
};
</script>

<template>
  <div>
    <LoadingOverlay :loading="loading">
      <LineChart :tabs="tabs" yAxisFormat="percent" />
    </LoadingOverlay>
  </div>
</template>

<script setup>
import LineChart from '@/components/charts/LineChart.vue';
import { dateToMonthName, dateToQuarter } from '@/services/utils';
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';
import { computed } from 'vue';

const props = defineProps({
  navPriceData: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const tabs = computed(() => [
  {
    name: '1 Mo',
    title: 'NAV/Price: 1 Mo',
    labels:
      props.navPriceData.mo1?.date?.map((_, index) => `Week ${index + 1}`) ||
      [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Price',
        data: props.navPriceData.mo1?.price?.map((x) => x) || [],
      },
      {
        label: 'NAV',
        data: props.navPriceData.mo1?.nav?.map((x) => x) || [],
      },
      {
        label: 'Peer Price',
        data: props.navPriceData.mo1?.price_peer?.map((x) => x) || [],
      },
      {
        label: 'Peer NAV',
        data: props.navPriceData.mo1?.nav_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '3 Mo',
    title: 'NAV/Price: 3 Mo',
    labels: props.navPriceData.mo3?.date?.map((x) => dateToMonthName(x)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Price',
        data: props.navPriceData.mo3?.price?.map((x) => x) || [],
      },
      {
        label: 'NAV',
        data: props.navPriceData.mo3?.nav?.map((x) => x) || [],
      },
      {
        label: 'Peer Price',
        data: props.navPriceData.mo3?.price_peer?.map((x) => x) || [],
      },
      {
        label: 'Peer NAV',
        data: props.navPriceData.mo3?.nav_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: 'YTD',
    title: 'NAV/Price: YTD',
    labels: props.navPriceData.ytd?.date?.map((x) => dateToQuarter(x)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Price',
        data: props.navPriceData.ytd?.price?.map((x) => x) || [],
      },
      {
        label: 'NAV',
        data: props.navPriceData.ytd?.nav?.map((x) => x) || [],
      },
      {
        label: 'Peer Price',
        data: props.navPriceData.ytd?.price_peer?.map((x) => x) || [],
      },
      {
        label: 'Peer NAV',
        data: props.navPriceData.ytd?.nav_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '1 Yr',
    title: 'NAV/Price: 1 YR',
    labels: props.navPriceData.yr1?.date?.map((x) => dateToQuarter(x)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Price',
        data: props.navPriceData.yr1?.price?.map((x) => x) || [],
      },
      {
        label: 'NAV',
        data: props.navPriceData.yr1?.nav?.map((x) => x) || [],
      },
      {
        label: 'Peer Price',
        data: props.navPriceData.yr1?.price_peer?.map((x) => x) || [],
      },
      {
        label: 'Peer NAV',
        data: props.navPriceData.yr1?.nav_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '3 Yr',
    title: 'NAV/Price: 3 YR',
    labels: props.navPriceData.yr3?.date?.map((x) => dateToQuarter(x)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Price',
        data: props.navPriceData.yr3?.price?.map((x) => x) || [],
      },
      {
        label: 'NAV',
        data: props.navPriceData.yr3?.nav?.map((x) => x) || [],
      },
      {
        label: 'Peer Price',
        data: props.navPriceData.yr3?.price_peer?.map((x) => x) || [],
      },
      {
        label: 'Peer NAV',
        data: props.navPriceData.yr3?.nav_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '5 Yr',
    title: 'NAV/Price: 5 YR',
    labels: props.navPriceData.yr5?.date?.map((x) => dateToQuarter(x)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Price',
        data: props.navPriceData.yr5?.price?.map((x) => x) || [],
      },
      {
        label: 'NAV',
        data: props.navPriceData.yr5?.nav?.map((x) => x) || [],
      },
      {
        label: 'Peer Price',
        data: props.navPriceData.yr5?.price_peer?.map((x) => x) || [],
      },
      {
        label: 'Peer NAV',
        data: props.navPriceData.yr5?.nav_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '10 Yr',
    title: 'NAV/Price: 10 YR',
    labels: props.navPriceData.yr10?.date?.map((x) => dateToQuarter(x)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Price',
        data: props.navPriceData.yr10?.price?.map((x) => x) || [],
      },
      {
        label: 'NAV',
        data: props.navPriceData.yr10?.nav?.map((x) => x) || [],
      },
      {
        label: 'Peer Price',
        data: props.navPriceData.yr10?.price_peer?.map((x) => x) || [],
      },
      {
        label: 'Peer NAV',
        data: props.navPriceData.yr10?.nav_peer?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '15 Yr',
    title: 'NAV/Price: 15 YR',
    labels: props.navPriceData.yr15?.date?.map((x) => dateToQuarter(x)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Price',
        data: props.navPriceData.yr15?.price?.map((x) => x) || [],
      },
      {
        label: 'NAV',
        data: props.navPriceData.yr15?.nav?.map((x) => x) || [],
      },
      {
        label: 'Peer Price',
        data: props.navPriceData.yr15?.price_peer?.map((x) => x) || [],
      },
      {
        label: 'Peer NAV',
        data: props.navPriceData.yr15?.nav_peer?.map((x) => x) || [],
      },
    ],
  },
]);
</script>

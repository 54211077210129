<template>
  <section
    ref="tabbedChart"
    :class="
      tabs.length > 1
        ? 'component spacing-top-none tabbed-chart is-visible'
        : 'component line-chart spacing-top-none is-visible'
    "
  >
    <ul v-if="tabs.length > 1" class="nav nav-tabs">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :class="['nav-item', { active: index === activeTabIndex }]"
      >
        <a
          href="#"
          @click="changeActiveTab($event, index)"
          :class="['nav-link', { active: index === activeTabIndex }]"
          >{{ tab.name || 'Tab ' + (index + 1) }}</a
        >
      </li>
      <li class="nav-item mobile dropdown">
        <a href="#" class="nav-link active" data-bs-toggle="dropdown">{{
          activeTabName
        }}</a>
        <ul class="dropdown-menu">
          <li
            v-for="(tab, index) in tabs"
            :key="'mobile-' + index"
            @click="changeActiveTab($event, index)"
          >
            <a href="#" class="dropdown-item">{{
              tab.name || 'Tab ' + (index + 1)
            }}</a>
          </li>
        </ul>
      </li>
    </ul>

    <h2 v-if="activeTabSubTitle">
      {{ activeTabTitle }} – <small>{{ activeTabSubTitle }}</small>
    </h2>
    <h2 v-else>{{ activeTabTitle }}</h2>

    <canvas ref="chartCanvas"></canvas>
  </section>
</template>

<script>
import {
  ref,
  onMounted,
  onUnmounted,
  watch,
  nextTick,
  computed,
  toRaw,
} from 'vue';
import Chart from 'chart.js/auto';
import { formatNumber } from '@/services/utils';

export default {
  props: {
    title: {
      type: String,
      default: 'My Chart',
    },
    type: {
      type: String,
      default: 'line',
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    xAxisColor: {
      type: String,
      default: '#E9EEF0',
    },
    yAxisColor: {
      type: String,
      default: '#E9EEF0',
    },
    yAxisFormat: {
      type: String,
      default: null,
    },
    colors: {
      type: Array,
      default: () => [
        '#21739C',
        '#4DBFDE',
        '#57E5AC',
        '#C766FF',
        '#f955af',
        '#4FDEA6',
        '#D11280',
        '#FF5799',
      ],
    },
  },
  setup(props) {
    const chartInstance = ref(null);
    const activeTabIndex = ref(0);
    const activeTabName = ref('');
    const activeTabTitle = ref('');
    const activeTabSubTitle = ref('');
    const tabs = ref(
      props.type === 'line'
        ? props.tabs.map((tab) => ({
            ...tab, // Preserve the original tab data
            datasets: tab.datasets.map((dataset, index) => ({
              ...dataset, // Preserve the original dataset data
              backgroundColor: props.colors[index % props.colors.length], // Assign color based on the index
              borderColor: props.colors[index % props.colors.length], // Assign color based on the index
              pointRadius: 0, // Hide points on the line
              pointHoverRadius: 6, // Show points with increased size when hovered
              hitRadius: 10, // Increase hover area padding
            })),
          }))
        : props.tabs
    );
    const chartCanvas = ref(null);

    const chartOptions = computed(() => ({
      responsive: true,
      aspectRatio: 2.0,
      scales: {
        y: {
          color: props.yAxisColor,
          grace: 1,
          font: {
            weight: 400,
            size: 14,
          },
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, ticks) {
              if (props.yAxisFormat && props.yAxisFormat === 'percent') {
                return formatNumber(value) + '%';
              }
              if (props.yAxisFormat && props.yAxisFormat === 'money') {
                return '$' + formatNumber(value);
              }
              return value;
            },
          },
        },
        x: {
          color: props.xAxisColor,
          grace: 1,
          grid: {
            drawOnChartArea: false,
            drawTicks: true,
          },
          font: {
            weight: 400,
            size: 14,
          },
        },
      },
      plugins: {
        tooltip: {
          titleFont: { weight: 600, size: 16 },
          padding: 12,
          bodyFont: { weight: 'normal', size: 14 },
        },
        legend:
          props.type === 'line'
            ? {
                position: 'bottom',
                title: {
                  display: true,
                  text: 'Click to disable',
                  padding: 10,
                  font: { weight: 600 },
                },
                labels: { font: { weight: 600, size: 14 } },
              }
            : { display: false },
      },
    }));

    const setActiveTab = (index) => {
      activeTabIndex.value = index;
      activeTabName.value = tabs.value[index].name || 'Tab ' + (index + 1);
      activeTabTitle.value = tabs.value[index].title || 'Item ' + (index + 1);
      activeTabSubTitle.value = tabs.value[index].subTitle || '';
    };

    const changeActiveTab = (event, index) => {
      event.preventDefault();
      setActiveTab(index);

      // Use Vue's nextTick to wait for DOM updates before updating the chart
      nextTick(() => {
        updateChartInstance(); // Ensure chart update happens after Vue DOM updates
      });
    };

    const updateChartInstance = async () => {
      if (!chartCanvas.value) return; // Ensure the canvas is available

      // Destroy existing chart instance if it's already created
      if (chartInstance.value) {
        toRaw(chartInstance.value).destroy();
        chartInstance.value = null; // Reset the chart instance
      }

      if (chartCanvas.value) {
        const ctx = chartCanvas.value.getContext('2d');

        if (ctx) {
          ctx.clearRect(
            0,
            0,
            chartCanvas.value.width,
            chartCanvas.value.height
          ); // Clear canvas before re-initializing

          chartInstance.value = new Chart(chartCanvas.value, {
            type: props.type,
            data: {
              labels: tabs.value[activeTabIndex.value].labels || [],
              datasets: tabs.value[activeTabIndex.value].datasets || [],
            },
            options: {
              responsive: chartOptions.value.responsive,
              aspectRatio: chartOptions.value.aspectRatio,
              scales: chartOptions.value.scales,
              plugins: chartOptions.value.plugins,
            },
          });
        }
      }
    };

    onMounted(() => {
      if (tabs.value.length > 0) {
        setActiveTab(activeTabIndex.value);
        updateChartInstance();
      }
    });
    onUnmounted(() => {
      if (chartInstance.value) {
        chartInstance.value.destroy();
      }
    });

    watch(
      () => props.tabs,
      (newTabs) => {
        tabs.value = newTabs;
        setActiveTab(activeTabIndex.value);
        updateChartInstance();
      }
    );

    return {
      activeTabIndex,
      activeTabName,
      activeTabTitle,
      activeTabSubTitle,
      setActiveTab,
      chartCanvas,
      changeActiveTab,
    };
  },
};
</script>

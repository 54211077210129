<template>
  <!-- NAV Beta (2 Yrs) -->
  <div class="table-responsive">
    <table class="table sidebar">
      <thead>
        <tr>
          <th colspan="2">{{ heading }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in correlationData" :key="`item_${index}`">
          <th>
            {{ item.label }}
          </th>
          <td>{{ item.value || 0 }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- /.table-responsive -->
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  shareClassData: {
    type: Object,
    default: () => ({}),
  },
  shareClass: {
    type: Object,
    default: () => ({}),
  },
});

const heading = computed(() =>
  props.shareClass?.fund?.is_fund_type_dbc
    ? 'Price Beta (2yr)'
    : 'NAV Beta (2yr)'
);

const correlationData = computed(() => {
  const { shareClassData } = props;

  return {
    priceCorrelationSP500: {
      label: 'vs. S&P 500 (TR)',
      value: shareClassData?.beta_2yr_sp500?.data_value || '-',
    },
    priceCorrelationCefaSectors: {
      label: "vs. CEFA's 12 Major CEF Sectors Index",
      value: shareClassData?.beta_12?.data_value || '-',
    },
    priceCorrelationCefaPreferred: {
      label: `vs. ${shareClassData?.beta_sector?.value_text || 'Preferred Equity Index'}`,
      value: shareClassData?.beta_sector?.data_value || '-',
    },
  };
});
</script>

<template>
  <nav id="navigation" class="fixed-top">
    <div id="navbar" class="navbar navbar-expand-lg">
      <div class="container">
        <a href="/" class="navbar-brand">
          <span class="visually-hidden">CEF Advisors</span>
          <img
            :src="require('@/assets/img/cefa-logo.png')"
            alt="CEF Advisors"
            class="logo"
          />
        </a>
        <button
          class="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#main-navigation"
          aria-controls="main-navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="fass fal fa-bars" aria-hidden="false"></span>
          <span class="fass fal fa-xmark" aria-hidden="true"></span></button
        ><!-- /.navbar-toggler -->
        <div id="main-navigation" class="collapse navbar-collapse">
          <form
            id="hdr-ticker-form"
            method="GET"
            class="form-inline"
            action="#"
            role="form"
            @submit="searchTicker"
          >
            <div class="input-group input-group-sm">
              <input
                type="text"
                placeholder="CEF OR BDC FUND TICKER"
                name="ticker"
                class="form-control"
                aria-label="Enter Fund Symbol"
                aria-describedby="ticker-label"
                v-model="ticker"
              />
              <button
                type="button"
                id="hdr-ticker-form-btn"
                @click="searchTicker"
              >
                <i class="far fa-magnifying-glass"></i
                ><span class="visually-hidden" id="search-label">Search</span>
              </button>
            </div>
            <!-- /.input-group -->
          </form>
          <!-- /#hdr-ticker-form -->
          <ul id="main-nav" class="navbar-nav ml-lg-auto">
            <li
              itemscope="itemscope"
              itemtype="https://www.schema.org/SiteNavigationElement"
              class="nav-item"
            >
              <a href="#" class="nav-link">About</a>
            </li>
            <li
              itemscope="itemscope"
              itemtype="https://www.schema.org/SiteNavigationElement"
              class="nav-item"
            >
              <a href="#" class="nav-link">Investing</a>
            </li>
            <li
              itemscope="itemscope"
              itemtype="https://www.schema.org/SiteNavigationElement"
              class="nav-item"
            >
              <a href="#" class="nav-link">Data</a>
            </li>
            <li
              itemscope="itemscope"
              itemtype="https://www.schema.org/SiteNavigationElement"
              class="nav-item"
            >
              <a href="#" class="nav-link">Resources</a>
            </li>
            <li
              itemscope="itemscope"
              itemtype="https://www.schema.org/SiteNavigationElement"
              class="nav-item"
            >
              <a href="#" class="nav-link">Contact</a>
            </li>
            <li
              itemscope="itemscope"
              itemtype="https://www.schema.org/SiteNavigationElement"
              class="nav-item"
            >
              <a href="#" class="btn btn-default btn-sm">Login</a>
            </li>
          </ul>
        </div>
        <!-- .#main-navigation -->
      </div>
      <!-- /.container -->
    </div>
    <!-- /#navbar -->
  </nav>
  <!-- /#navigation -->
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const ticker = ref('');
const router = useRouter();

const searchTicker = (event) => {
  event.preventDefault();
  const ticker_value = ticker.value.trim();
  if (ticker_value != '') {
    router.push({ name: 'FundProfile', params: { ticker: ticker_value } });
  }
};
</script>

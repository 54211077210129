<template>
  <div class="table-responsive">
    <table class="table sidebar">
      <thead>
        <tr>
          <th colspan="2">Correlation & Volatility</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in correlationData" :key="`item_${index}`">
          <th>
            {{ item.label }}
            <small v-if="item.subLabel">{{ item.subLabel }}</small>
          </th>
          <td>{{ item.value || 0 }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- /.table-responsive -->
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
  shareClassData: {
    type: Object,
    default: () => ({}),
  },
  shareClass: {
    type: Object,
    default: () => ({}),
  },
});

const correlationData = computed(() => {
  const { shareClassData, shareClass } = props;

  return {
    priceCorrelationSP500: {
      label: 'Price Correlation (1 Yr)',
      subLabel: 'vs. S&P 500 (TR)',
      value: shareClassData?.corr_sp500?.data_value || '-',
    },
    priceCorrelationCefaSectors: {
      label: 'Price Correlation (1 Yr)',
      subLabel: "vs. CEFA's 12 Major CEF Sectors Index",
      value: shareClassData?.corr_12_sector?.data_value || '-',
    },
    priceCorrelationCefaPreferred: {
      label: 'Price Correlation (1 Yr)',
      subLabel: `vs. ${shareClassData?.corr_sector?.value_text || 'Preferred Equity Index'}`,
      value: shareClassData?.corr_sector?.data_value || '-',
    },
    mostCorrelatedPeer: {
      label: `Most Correlated Peer (Mk Pr): ${shareClassData?.most_correlated_peer?.value_text || 'N/A'}`,
      value: shareClassData?.most_correlated_peer?.data_value || '-',
    },
    ...(!shareClass?.fund?.is_fund_type_dbc
      ? {
          navPriceCorrelation30D: {
            label: 'NAV - Price Correlation (30 D)',
            value: shareClassData?.corr_nav_price_30d?.data_value || '-',
          },
          navPriceCorrelation90D: {
            label: 'NAV - Price Correlation (90 D)',
            value: shareClassData?.corr_nav_price_90d?.data_value || '-',
          },
          navPriceCorrelation1Yr: {
            label: 'NAV - Price Correlation (1 Yr)',
            value: shareClassData?.corr_nav_price_1yr?.data_value || '-',
          },
        }
      : {}),
    priceStdDev1Yr: {
      label: 'Price St Dev (1 Yr)',
      value: shareClassData?.price_volatility_1yr?.data_value || '-',
    },
    navStdDev1Yr: {
      label: 'NAV St Dev (1 Yr)',
      value: shareClassData?.nav_volatility_1yr?.data_value || '-',
    },
  };
});
</script>

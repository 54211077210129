// main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { setup } from './libs/axios'; // Import the setup function
import './assets/scss/cefa.scss';
import Toast from 'vue-toastification';

const app = createApp(App);

app.use(router);
app.use(Toast, {});
setup(router);

app.mount('#app');

<template>
  <div class="table-responsive">
    <table class="table sidebar">
      <thead>
        <tr>
          <th colspan="2">Z-Stat</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in zStatData" :key="`item_${index}`">
          <th>
            {{ item.label }}
          </th>
          <td>{{ item.value || 0 }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- /.table-responsive -->
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
  shareClassData: {
    type: Object,
    default: () => ({}), // Default value as an empty object
  },
});

const zStatData = computed(() => {
  const { shareClassData } = props;

  return {
    zStat6Mo: {
      label: 'Z-Stat (6 Mo)',
      value: shareClassData.z_stat_6mo?.data_value,
    },
    zStat1Yr: {
      label: 'Z-Stat (1 Yr)',
      value: shareClassData.z_stat_1yr?.data_value,
    },
    zStat3Yr: {
      label: 'Z-Stat (3 Yr)',
      value: shareClassData.z_stat_3yr?.data_value,
    },
    compZStat6Mo: {
      label: 'Comp Z-Stat (6 Mo)',
      value: shareClassData.z_stat_comp_6mo?.data_value,
    },
    compZStat1Yr: {
      label: 'Comp Z-Stat (1 Yr)',
      value: shareClassData.z_stat_comp_1yr?.data_value,
    },
    compZStat3Yr: {
      label: 'Comp Z-Stat (3 Yr)',
      value: shareClassData.z_stat_comp_3yr?.data_value,
    },
  };
});
</script>

<template>
  <div class="table-responsive">
    <table class="table sidebar">
      <thead>
        <tr>
          <th>Current</th>
          <td>Data as of {{ tableData.currentDate }}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Market Price</th>
          <td>
            <big>${{ tableData.marketPrice.price }}</big>
            <small>( {{ tableData.marketPrice.change }}) </small>
          </td>
        </tr>
        <tr>
          <th>Nav (as of {{ tableData.nav.date }})</th>
          <td>
            <big>${{ tableData.nav.nav }}</big>
            <small>( {{ tableData.nav.change }}) </small>
          </td>
        </tr>
        <tr>
          <th>Premium/(Discount)</th>
          <td>
            <big>{{ tableData.premiumDiscount.disc }}%</big>
            <small>( {{ tableData.premiumDiscount.change }}%) </small>
          </td>
        </tr>
        <tr>
          <th>Market Yield</th>
          <td>
            <big>{{ tableData.marketYield }}%</big>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import {
  formatDateToString,
  formatValueChange,
  formatDateToUSA,
  formatNumber,
} from '@/services/utils';

const props = defineProps({
  shareClass: {
    type: Object,
    default: () => ({}), // Default value as an empty object
  },
});

const tableData = computed(() => ({
  currentDate: formatDateToString(props.shareClass?.price?.date),
  marketPrice: {
    price: props.shareClass?.price?.price,
    change: formatValueChange(
      props.shareClass?.price?.change_from_previous?.price_change
    ),
  },
  nav: {
    nav: props.shareClass?.price?.nav,
    date: formatDateToUSA(props.shareClass?.price?.nav_date_display),
    change: formatValueChange(
      props.shareClass?.price?.change_from_previous?.nav_change
    ),
  },
  premiumDiscount: {
    disc: formatNumber(props.shareClass?.price?.disc, 2),
    change: formatValueChange(
      props.shareClass?.price?.change_from_previous?.disc_change
    ),
  },
  marketYield: formatNumber(props.shareClass?.price?.mk_yield, 2),
}));
</script>

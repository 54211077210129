<template>
  <div>
    <BarChart title="1 Year Distribution Levels" :chartData="chartData" />
  </div>
</template>

<script setup>
import BarChart from '@/components/charts/BarChart.vue';

const props = defineProps({
  shareClassData: {
    type: Object,
    default: () => ({}),
  },
});

const chartData = {
  type: 'bar',
  data: {
    labels: ['Income', 'ST Gains', 'LT Gains', 'RoC'],
    datasets: [
      {
        label: '1 Year Distribution Levels',
        data: [
          props.shareClassData?.distribution_inc_1yr_pct?.data_value,
          props.shareClassData?.distribution_st_1yr_pct?.data_value,
          props.shareClassData?.distribution_lt_1yr_pct?.data_value,
          props.shareClassData?.distribution_roc_1yr_pct?.data_value,
        ],
        backgroundColor: ['#ACAEB1', '#ACAEB1', '#ACAEB1', '#ACAEB1'],
        borderColor: ['#ACAEB1', '#ACAEB1', '#ACAEB1', '#ACAEB1'],
        barPercentage: 0.5,
      },
    ],
  },
  options: {
    responsive: true,
    aspectRatio: 1.5,
    scales: {
      x: {
        font: {
          size: 16,
          color: '#0A263D',
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        font: {
          size: 16,
          color: '#0A263D',
        },
        ticks: {
          callback: (val) => {
            return val + '%';
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  },
};
</script>

<template>
  <section class="component dark spacing-top none px-4 is-visible">
    <h2>DISTRIBUTION HISTORY (1 YR)</h2>
    <div class="table-responsive">
      <table class="table dark-table" ref="dataTable">
        <thead>
          <tr class="arrows">
            <th>Declared</th>
            <th>Payable</th>
            <th>Ex Date</th>
            <th>Distrib</th>
            <th>Income</th>
            <th>Short Gain</th>
            <th>Long Gain</th>
            <th>Roc</th>
            <th class="label">Type</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in first12DividendHistoryData"
            :key="`item_${index}`"
          >
            <td>{{ item.declared_date }}</td>
            <td>{{ item.payable_date }}</td>
            <td>{{ item.ex_date }}</td>
            <td>{{ item.distribution_amount }}</td>
            <td>{{ item.income }}</td>
            <td>{{ item.short_gain }}</td>
            <td>{{ item.long_gain }}</td>
            <td>{{ item.roc }}</td>
            <td class="label">{{ item.type }}</td>
          </tr>
        </tbody>
        <caption>
          Click on any value in "Peer Group" rows to see full peer group total
          returns.
        </caption>
      </table>
    </div>
    <!-- /.table-responsive -->
  </section>
</template>

<script setup>
import { ref, onBeforeMount, computed } from 'vue';
import DataTable from 'datatables.net';
import { formatDateToUSA, formatNumber } from '@/services/utils';

// Define props
const props = defineProps({
  dividendHistory: {
    type: Array,
    default: () => [],
  },
});

// Create a ref for the table element
const dataTable = ref(null);

// Process and format dividend history data
const dividendHistoryData = computed(() =>
  props.dividendHistory
    .filter((x) => x.declared_date && Object.keys(x.declared_date).length > 0)
    .map((x) => {
      return {
        declared_date: formatDateToUSA(x.declared_date),
        payable_date: formatDateToUSA(x.payable_date),
        ex_date: formatDateToUSA(x.ex_date),
        distribution_amount: `$${formatNumber(x.distribution_amount, 4)}`,
        income: `$${formatNumber(x.income, 4)}`,
        short_gain: `$${formatNumber(x.short_gain, 4)}`,
        long_gain: `$${formatNumber(x.long_gain, 4)}`,
        roc: `$${formatNumber(x.roc, 4)}`,
        type: x.get_dividend_type_display,
      };
    })
);

const first12DividendHistoryData = computed(
  () => dividendHistoryData?.value?.slice(0, 12) || []
);

onBeforeMount(() => {
  if (dataTable.value) {
    new DataTable(dataTable.value, {
      searching: false,
      paging: false,
      columns: [
        { data: 'declared_date', orderable: true },
        { data: 'payable_date', orderable: true },
        { data: 'ex_date', orderable: true },
        { data: 'distribution_amount', orderable: true },
        { data: 'income', orderable: true },
        { data: 'short_gain', orderable: true },
        { data: 'long_gain', orderable: true },
        { data: 'roc', orderable: true },
        { data: 'type', orderable: false },
      ],
    });
  }
});
</script>

<template>
  <div>
    <LoadingOverlay :loading="loading">
      <LineChart :tabs="tabs" yAxisFormat="percent" />
    </LoadingOverlay>
  </div>
</template>

<script setup>
import LineChart from '@/components/charts/LineChart.vue';
import { dateToMonthName, dateToQuarter } from '@/services/utils';
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';
import { computed } from 'vue';

const props = defineProps({
  yieldData: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const tabs = computed(() => [
  {
    name: '1 Mo',
    title: 'INDICATED YIELD: 1 Mo',
    labels:
      props.yieldData.mo1?.date?.map((_, index) => `Week ${index + 1}`) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Market Yield',
        data: props.yieldData.mo1?.yield?.map((x) => x) || [],
      },
      {
        label: 'Peer Group',
        data: props.yieldData.mo1?.yield_peer?.map((x) => x) || [],
      },
      {
        label: 'Average',
        data: props.yieldData.mo1?.yield_fund_average?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '3 Mo',
    title: 'INDICATED YIELD: 3 Mo',
    labels: props.yieldData.mo3?.date?.map((x) => dateToMonthName(x)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Market Yield',
        data: props.yieldData.mo3?.yield?.map((x) => x) || [],
      },
      {
        label: 'Peer Group',
        data: props.yieldData.mo3?.yield_peer?.map((x) => x) || [],
      },
      {
        label: 'Average',
        data: props.yieldData.mo3?.yield_fund_average?.map((x) => x) || [],
      },
    ],
  },
  {
    name: 'YTD',
    title: 'INDICATED YIELD: YTD',
    labels: props.yieldData.ytd?.date?.map((x) => dateToQuarter(x)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Market Yield',
        data: props.yieldData.ytd?.yield?.map((x) => x) || [],
      },
      {
        label: 'Peer Group',
        data: props.yieldData.ytd?.yield_peer?.map((x) => x) || [],
      },
      {
        label: 'Average',
        data: props.yieldData.ytd?.yield_fund_average?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '1 Yr',
    title: 'INDICATED YIELD: 1 YR',
    labels: props.yieldData.yr1?.date?.map((x) => dateToQuarter(x)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Market Yield',
        data: props.yieldData.yr1?.yield?.map((x) => x) || [],
      },
      {
        label: 'Peer Group',
        data: props.yieldData.yr1?.yield_peer?.map((x) => x) || [],
      },
      {
        label: 'Average',
        data: props.yieldData.yr1?.yield_fund_average?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '3 Yr',
    title: 'INDICATED YIELD: 3 YR',
    labels: props.yieldData.yr3?.date?.map((x) => dateToQuarter(x)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Market Yield',
        data: props.yieldData.yr3?.yield?.map((x) => x) || [],
      },
      {
        label: 'Peer Group',
        data: props.yieldData.yr3?.yield_peer?.map((x) => x) || [],
      },
      {
        label: 'Average',
        data: props.yieldData.yr3?.yield_fund_average?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '5 Yr',
    title: 'INDICATED YIELD: 5 YR',
    labels: props.yieldData.yr5?.date?.map((x) => dateToQuarter(x)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Market Yield',
        data: props.yieldData.yr5?.yield?.map((x) => x) || [],
      },
      {
        label: 'Peer Group',
        data: props.yieldData.yr5?.yield_peer?.map((x) => x) || [],
      },
      {
        label: 'Average',
        data: props.yieldData.yr5?.yield_fund_average?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '10 Yr',
    title: 'INDICATED YIELD: 10 YR',
    labels: props.yieldData.yr10?.date?.map((x) => dateToQuarter(x)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Market Yield',
        data: props.yieldData.yr10?.yield?.map((x) => x) || [],
      },
      {
        label: 'Peer Group',
        data: props.yieldData.yr10?.yield_peer?.map((x) => x) || [],
      },
      {
        label: 'Average',
        data: props.yieldData.yr10?.yield_fund_average?.map((x) => x) || [],
      },
    ],
  },
  {
    name: '15 Yr',
    title: 'INDICATED YIELD: 15 YR',
    labels: props.yieldData.yr15?.date?.map((x) => dateToQuarter(x)) || [],
    yAxisFormat: 'percent',
    datasets: [
      {
        label: 'Market Yield',
        data: props.yieldData.yr15?.yield?.map((x) => x) || [],
      },
      {
        label: 'Peer Group',
        data: props.yieldData.yr15?.yield_peer?.map((x) => x) || [],
      },
      {
        label: 'Average',
        data: props.yieldData.yr15?.yield_fund_average?.map((x) => x) || [],
      },
    ],
  },
]);
</script>

<template>
  <!-- Bond Specific Data -->
  <div class="table-responsive">
    <table class="table sidebar">
      <thead>
        <tr>
          <th colspan="2">Bond Specific Data</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in bondData" :key="`item_${index}`">
          <th>
            {{ item.label }}
          </th>
          <td>{{ item.value || 0 }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- /.table-responsive -->
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
  shareClassData: {
    type: Object,
    default: () => ({}),
  },
  shareClass: {
    type: Object,
    default: () => ({}),
  },
});

// Computed property for all the rows in the table
const bondData = computed(() => {
  const { shareClass, shareClassData } = props;

  // Case for Bond Funds
  if (shareClass.fund?.is_fund_main_group_bond) {
    return {
      maturity: {
        label: 'Maturity',
        value: shareClassData?.maturity_weighted?.data_value || '-',
      },
      duration: {
        label: 'Duration',
        value: shareClassData?.duration_value?.data_value || '-',
      },
      creditRating: {
        label: 'Credit Rating (Rated Bonds Only)',
        value: shareClassData?.credit_rating_rbo?.data_value || '-',
      },
      investmentGrade: {
        label: 'Investment Grade',
        value: `${shareClassData?.investment_grade?.data_value || '-'}%`,
      },
      nonInvestmentGrade: {
        label: 'Non Investment Grade',
        value: `${shareClassData?.non_investment_grade?.data_value || '-'}%`,
      },
      unratedBonds: {
        label: 'Unrated Bonds',
        value: `${shareClassData?.credit_rating_breakdown?.data_value?.u || '-'}%`,
      },
    };
  }

  // Case for Muni Bond Funds
  if (
    shareClass.fund?.main_group === 'Single State Muni Bond Funds' ||
    shareClass.fund?.main_group === 'National Muni Bond Funds'
  ) {
    return {
      maturity: {
        label: 'Maturity',
        value: shareClassData?.maturity_weighted?.data_value || '-',
      },
      averageBondPrice: {
        label: 'Average Bond Price',
        value: shareClassData?.average_bond_price?.data_value || '-',
      },
      discountVsPrice: {
        label: 'Discount vs Price',
        value: shareClassData?.discount_vs_price?.data_value || '-',
      },
      coupon: {
        label: 'Coupon',
        value: `${shareClassData?.coupon_avg?.data_value || '-'}%`,
      },
      zeroCoupon: {
        label: 'Zero Coupon',
        value: `${shareClassData?.coupon_zero?.data_value || '-'}%`,
      },
      amt: {
        label: 'AMT',
        value: `${shareClassData?.amt_pct?.data_value || '-'}%`,
      },
      amtYield: {
        label: 'AMT Yield',
        value: `${shareClassData?.amt_yield?.data_value || '-'}%`,
      },
      bondCallsRisk: {
        label: 'Bond Calls Risk',
        value: `${shareClassData?.bond_calls_breakdown?.data_value?.b1 || '-'}%`,
      },
    };
  }

  // Default case
  return {
    maturity: {
      label: 'Maturity',
      value: shareClassData?.maturity_weighted?.data_value || '-',
    },
  };
});
</script>

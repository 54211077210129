<template>
  <section class="sidebar-chart">
    <h3>{{ title }}</h3>

    <canvas ref="chartCanvas"></canvas>
  </section>
</template>

<script>
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
import Chart from 'chart.js/auto';

export default {
  props: {
    title: {
      type: String,
      default: 'My Chart',
    },
    chartData: {
      type: Object,
      default: () => {},
    },
    colors: {
      type: Array,
      default: () => ['#ACAEB1', '#ACAEB1', '#ACAEB1', '#ACAEB1'],
    },
  },

  setup(props) {
    const chartInstance = ref(null);
    const chartCanvas = ref(null);

    const drawChartInstance = async () => {
      if (!chartCanvas.value) return; // Ensure the canvas is available

      // Destroy existing chart instance if it's already created
      if (chartInstance.value) {
        chartInstance.value.destroy();
        chartInstance.value = null; // Reset the chart instance
      }

      nextTick(() => {
        if (chartCanvas.value) {
          const ctx = chartCanvas.value.getContext('2d');

          if (ctx) {
            ctx.clearRect(
              0,
              0,
              chartCanvas.value.width,
              chartCanvas.value.height
            ); // Clear canvas before re-initializing

            chartInstance.value = new Chart(chartCanvas.value, props.chartData);
          }
        }
      });
    };

    onMounted(() => {
      drawChartInstance();
    });

    onUnmounted(() => {
      if (chartInstance.value) {
        chartInstance.value.destroy();
      }
    });

    return {
      chartCanvas,
    };
  },
};
</script>

<template>
  <LoadingOverlay :loading="loading">
    <div class="table-responsive">
      <table class="table sidebar">
        <thead>
          <tr>
            <th colspan="2">Universal Average Discount Data</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, key) in tableData" :key="key">
            <th>{{ item.label }}</th>
            <td>{{ item.value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- /.table-responsive -->
  </LoadingOverlay>
</template>

<script setup>
import { computed } from 'vue';
import { formatNumber } from '@/services/utils';
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';

const props = defineProps({
  fundProfileDiscount: {
    type: Object,
    default: () => ({}),
  },
  shareClass: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: true,
  },
});

const tableData = computed(() => ({
  all_funds: {
    label: 'Average Premium/(Discount)',
    value: formatNumber(props.fundProfileDiscount?.all_funds, 1) + '%',
  },
  cef_funds: {
    label: 'All Closed-End Funds',
    value: formatNumber(props.fundProfileDiscount?.cef_funds, 1) + '%',
  },
  cef_equity: {
    label: 'Equity Funds',
    value: formatNumber(props.fundProfileDiscount?.cef_equity, 1) + '%',
  },
  cef_taxable_bond: {
    label: 'Taxable Bond Funds',
    value: formatNumber(props.fundProfileDiscount?.cef_taxable_bond, 1) + '%',
  },
  bdc_funds: {
    label: 'Business Development Companies (BDCs)',
    value: formatNumber(props.fundProfileDiscount?.bdc_funds, 1) + '%',
  },
  cef_muni_bond: {
    label: 'Municipal Bond Funds',
    value: formatNumber(props.fundProfileDiscount?.cef_muni_bond, 1) + '%',
  },
  group: {
    label: props.shareClass?.fund?.group,
    value: formatNumber(props.fundProfileDiscount?.group, 1) + '%',
  },
}));
</script>

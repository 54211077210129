<!-- Home.vue -->
<template>
  <div id="content">
    <LoadingOverlay :loading="isLoading">
      <div>
        <Header
          :shareClass="shareClass"
          :relatedIndexes="relatedIndexes"
        ></Header>
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-md-5 col-lg-5 col-xl-4 charts-tab show">
              <Current :shareClass="shareClass"></Current>
              <div class="col-spacer"></div>
              <FundInfo :shareClass="shareClass"></FundInfo>
              <div class="col-spacer"></div>
              <CapitalAndLeverage
                :shareClass="shareClass"
                :shareClassData="shareClassData"
              ></CapitalAndLeverage>
              <div class="col-spacer"></div>
              <DicountData :shareClassData="shareClassData"></DicountData>
              <div class="col-spacer"></div>
              <ZStat :shareClassData="shareClassData"></ZStat>
              <div class="col-spacer"></div>
              <DistributionData
                :shareClass="shareClass"
                :shareClassData="shareClassData"
              ></DistributionData>
              <div class="col-spacer"></div>
              <TaxDividendData
                :taxDividendData="taxDividend"
                :loading="loadingTaxDividend"
              ></TaxDividendData>
            </div>
            <div class="col-xs-12 col-md-7 col-lg-7 col-xl-8 graphs-tab">
              <GrowthChart
                :growthData="growth10k"
                :loading="loadingGrowth10k"
              ></GrowthChart>
              <PremiumDiscountChart
                :discountData="priceChartData"
                :loading="loadingPriceChartData"
              ></PremiumDiscountChart>
              <NavPriceChart
                :navPriceData="priceChartData"
                :loading="loadingPriceChartData"
              ></NavPriceChart>
              <IndicatedYieldChart
                :yieldData="priceChartData"
                :loading="loadingPriceChartData"
              ></IndicatedYieldChart>
              <TotalReturn
                :reinvestedPriceData="reinvestedPriceData"
                :cashPriceData="cashPriceData"
                :ticker="ticker"
                :loading="loadingReinvestedPriceData || loadingCashPriceData"
              ></TotalReturn>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
          <!-- Ad Space Banner -->
          <div class="row">
            <div class="col">
              <section class="component is-visible">
                <div class="mock-ad-space banner"><div>Ad Space</div></div>
              </section>
              <!-- /.component -->
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
          <div class="row">
            <div class="col-xs-12 col-md-5 col-lg-5 col-xl-4 charts-tab show">
              <DistributionLevel
                :shareClassData="shareClassData"
              ></DistributionLevel>
              <div class="col-spacer"></div>
              <VolumeAndLiquidity
                :shareClassData="shareClassData"
              ></VolumeAndLiquidity>
              <div class="col-spacer"></div>
              <CorrelationAndVolatility
                :shareClass="shareClass"
                :shareClassData="shareClassData"
              ></CorrelationAndVolatility>
              <div class="col-spacer"></div>
              <NavBeta
                :shareClass="shareClass"
                :shareClassData="shareClassData"
              ></NavBeta>
              <div class="col-spacer"></div>
              <BondSpecificData
                :shareClass="shareClass"
                :shareClassData="shareClassData"
              ></BondSpecificData>
              <div class="col-spacer"></div>
              <Earnings
                :shareClass="shareClass"
                :shareClassData="shareClassData"
              ></Earnings>
              <div class="col-spacer"></div>
              <TopHoldings
                :topHoldings="topHoldings"
                :shareClass="shareClass"
                :shareClassData="shareClassData"
                :loading="loadingTopHoldings"
              ></TopHoldings>
            </div>
            <!-- /.col -->
            <div class="col-xs-12 col-md-7 col-lg-7 col-xl-8 graphs-tab">
              <AnnualizedTotalReturn
                :ticker="ticker"
                :reinvestedPriceData="reinvestedPriceData"
                :loading="loadingReinvestedPriceData"
              ></AnnualizedTotalReturn>
              <CalendarTotalReturn
                :ticker="ticker"
                :reinvestedPriceData="reinvestedPriceData"
                :loading="loadingReinvestedPriceData"
              ></CalendarTotalReturn>
              <DistributionHistory
                :dividendHistory="dividendHistory"
              ></DistributionHistory>

              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <CreditQuality
                    :shareClassData="shareClassData"
                  ></CreditQuality>
                </div>
                <!-- /.col -->
                <div class="col-xs-12 col-md-6">
                  <CallSchedule></CallSchedule>
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
          <div class="row align-items-end">
            <div class="col-xs-12 col-md-4">
              <AssetType :shareClassData="shareClassData"></AssetType>
            </div>
            <!-- /.col -->
            <div class="col-xs-12 col-md-4">
              <GeographicalExposure
                :shareClassData="shareClassData"
              ></GeographicalExposure>
            </div>
            <!-- /.col -->
            <div class="col-xs-12 col-md-4">
              <IndustryExposure
                :shareClassData="shareClassData"
              ></IndustryExposure>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
          <div class="row" v-if="!isEmpty(uniiShare)">
            <div class="col-xs-12 col-md-6">
              <UiiShare
                :uniiShare="uniiShare"
                :loading="loadingUniiShare"
              ></UiiShare>
            </div>
            <!-- /.col -->
            <div class="col-xs-12 col-md-6">
              <RelUnii
                :uniiShare="uniiShare"
                :loading="loadingUniiShare"
              ></RelUnii>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
          <div class="row" v-if="!isEmpty(earnings)">
            <div class="col-xs-12 col-md-6">
              <EarningsShare
                :earnings="earnings"
                :loading="loadingEarnings"
              ></EarningsShare>
            </div>
            <!-- /.col -->
            <div class="col-xs-12 col-md-6">
              <EarningsCoverage
                :earnings="earnings"
                :loading="loadingEarnings"
              ></EarningsCoverage>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
          <div class="row">
            <div class="col-xs-12 col-md-5 col-lg-5 col-xl-4 charts-tab show">
              <UniversalAvgDiscountData
                :shareClass="shareClass"
                :fundProfileDiscount="fundProfileDiscount"
                :loading="loadingFundProfileDiscount"
              ></UniversalAvgDiscountData>
            </div>
            <!-- /.col -->
            <div class="col-xs-12 col-md-7 col-lg-7 col-xl-8 graphs-tab">
              <section class="component spacing-top-none is-visible">
                <div class="mock-ad-space rectangle"><div>Ad Space</div></div>
              </section>
              <!-- /.component -->
            </div>
            <!-- /.col -->
          </div>
        </div>
      </div>
    </LoadingOverlay>
  </div>
</template>

<script setup>
import Current from './components/sub-components/Current.vue';
import FundInfo from './components/sub-components/FundInfo.vue';
import CapitalAndLeverage from './components/sub-components/CapitalAndLeverage.vue';
import DicountData from './components/sub-components/DicountData.vue';
import ZStat from './components/sub-components/ZStat.vue';
import DistributionData from './components/sub-components/DistributionData.vue';
import TaxDividendData from './components/sub-components/TaxDividendData.vue';
import GrowthChart from './components/chart-components/GrowthChart.vue';
import PremiumDiscountChart from './components/chart-components/PremiumDiscountChart.vue';
import NavPriceChart from './components/chart-components/NavPriceChart.vue';
import IndicatedYieldChart from './components/chart-components/IndicatedYieldChart.vue';
import TotalReturn from './components/sub-components/TotalReturn.vue';
import DistributionLevel from './components/chart-components/DistributionLevel.vue';
import VolumeAndLiquidity from './components/sub-components/VolumeAndLiquidity.vue';
import CorrelationAndVolatility from './components/sub-components/CorrelationAndVolatility.vue';
import NavBeta from './components/sub-components/NavBeta.vue';
import BondSpecificData from './components/sub-components/BondSpecificData.vue';
import Earnings from './components/sub-components/Earnings.vue';
import TopHoldings from './components/sub-components/TopHoldings.vue';
import AnnualizedTotalReturn from './components/chart-components/AnnualizedTotalReturn.vue';
import CalendarTotalReturn from './components/chart-components/CalendarTotalReturn.vue';
import DistributionHistory from './components/sub-components/DistributionHistory.vue';
import CreditQuality from './components/chart-components/CreditQuality.vue';
import CallSchedule from './components/chart-components/CallSchedule.vue';
import AssetType from './components/chart-components/AssetType.vue';
import GeographicalExposure from './components/chart-components/GeographicalExposure.vue';
import IndustryExposure from './components/chart-components/IndustryExposure.vue';
import UiiShare from './components/chart-components/UiiShare.vue';
import EarningsShare from './components/chart-components/EarningsShare.vue';
import EarningsCoverage from './components/chart-components/EarningsCoverage.vue';
import RelUnii from './components/chart-components/RelUnii.vue';
import UniversalAvgDiscountData from './components/sub-components/UniversalAvgDiscountData.vue';
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';

import isEmpty from 'ramda/src/isEmpty'; // Import only isEmpty

import Header from './components/Header.vue';
import { useFundProfile } from './composable/useFundProfile';
import { onBeforeMount, computed, watch, ref } from 'vue';
import { useRoute } from 'vue-router';

// Import reactive loading states from useFundProfile
const {
  fetchShareClass,
  fetchShareClassData,
  fetchTaxDividend,
  fetchUniiShare,
  fetchEarnings,
  fetchRelatedIndexes,
  fetchTopHoldings,
  fetchGrowth10k,
  fetchPriceChartData,
  fetchReinvestedPriceData,
  fetchCashPriceData,
  fetchDividendHistory,
  fetchFundProfileDiscount,

  loadingShareClass,
  loadingShareClassData,
  loadingTaxDividend,
  loadingUniiShare,
  loadingEarnings,
  loadingRelatedIndexes,
  loadingTopHoldings,
  loadingGrowth10k,
  loadingPriceChartData,
  loadingReinvestedPriceData,
  loadingCashPriceData,
  loadingFundProfileDiscount,

  shareClass,
  shareClassData,
  taxDividend,
  uniiShare,
  earnings,
  relatedIndexes,
  topHoldings,
  growth10k,
  priceChartData,
  reinvestedPriceData,
  cashPriceData,
  dividendHistory,
  fundProfileDiscount,
} = useFundProfile();

// Dynamically compute `isLoading` based on loading states
const isLoading = computed(
  () =>
    loadingRelatedIndexes.value ||
    loadingShareClass.value ||
    loadingShareClassData.value
);

const route = useRoute();
const ticker = ref('');

const fetchData = async (ticker) => {
  if (ticker !== '') {
    await Promise.all([
      fetchShareClass(ticker),
      fetchShareClassData(ticker),
      fetchTaxDividend(ticker),
      fetchUniiShare(ticker),
      fetchEarnings(ticker),
      fetchRelatedIndexes(ticker),
      fetchTopHoldings(ticker),
      fetchGrowth10k(ticker),
      fetchPriceChartData(ticker),
      fetchReinvestedPriceData(ticker),
      fetchCashPriceData(ticker),
      fetchDividendHistory(ticker),
      fetchFundProfileDiscount(ticker),
    ]);
  }
};

// Fetch all data on mount
onBeforeMount(async () => {
  ticker.value = route.params?.ticker || '';
  await fetchData(ticker.value);
});

// Watch for changes to the ticker parameter
watch(
  () => route.params.ticker,
  async (newTicker) => {
    if (newTicker) {
      ticker.value = newTicker;
      await fetchData(newTicker);
    }
  }
);
</script>

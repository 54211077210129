import axios from 'axios';
import { getCookie } from '@/services/useCookie';

let router; // Declare a variable for the router instance

// Function to set up the router
export function setup(routerInstance) {
  router = routerInstance; // Assign the passed router instance to the variable
}

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_REQUEST_API,
});

axiosIns.interceptors.request.use(
  (config) => {
    const accessToken = getCookie('access-token');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosIns.interceptors.response.use(undefined, function (error) {
  error.handleGlobally = errorHandler(error);
  return Promise.reject(error);
});

const errorHandler = (error) => {
  return () => {
    const statusCode = error.response ? error.response.status : null;
    switch (statusCode) {
      case 401:
        if (router) {
          router.push('/login'); // Use the router instance
        }
        break;
      // Handle other cases as needed...
    }
  };
};

export default axiosIns; // Export the axios instance

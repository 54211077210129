<template>
  <LoadingOverlay :loading="loading">
    <section
      id="tabbed-tables-1"
      class="component tabbed-tables dark is-visible"
    >
      <ul class="nav nav-tabs" id="tabbed-tables-1-nav" role="tablist">
        <li
          :class="['nav-item', { active: activeTab === 'reinvestment_tab' }]"
          role="presentation"
        >
          <button
            :class="['nav-link', { active: activeTab === 'reinvestment_tab' }]"
            id="tabbed-tables-1-tab-1"
            data-bs-toggle="tab"
            data-bs-target="#tabbed-tables-1-tab-1-pane"
            type="button"
            role="tab"
            aria-controls="tabbed-tables-1-tab-1-pane"
            :aria-selected="activeTab === 'reinvestment_tab'"
            @click="setActiveTab('reinvestment_tab')"
          >
            With Reinvestment
          </button>
        </li>
        <li
          :class="['nav-item', { active: activeTab === 'cash_dividend_tab' }]"
          role="presentation"
        >
          <button
            :class="['nav-link', { active: activeTab === 'cash_dividend_tab' }]"
            id="tabbed-tables-1-tab-2"
            data-bs-toggle="tab"
            data-bs-target="#tabbed-tables-1-tab-2-pane"
            type="button"
            role="tab"
            aria-controls="tabbed-tables-1-tab-2-pane"
            tabindex="-1"
            :aria-selected="activeTab === 'cash_dividend_tab'"
            @click="setActiveTab('cash_dividend_tab')"
          >
            Cash Dividends
          </button>
        </li>
      </ul>
      <div class="tab-content" id="tabbed-tables-content">
        <div
          class="tab-pane show active"
          id="tabbed-tables-1-tab-1-pane"
          role="tabpanel"
          aria-labelledby="tabbed-tables-1-tab-1"
          tabindex="0"
        >
          <h2>{{ currentTableData.title }}</h2>
          <div class="note">{{ currentTableData.note }}</div>
          <div class="table-responsive">
            <table id="tabbed-tables-1-tab-1-table" class="table dark-table">
              <thead>
                <tr>
                  <th
                    v-for="(item, index) in currentTableData.headers"
                    :key="index"
                  >
                    {{ item }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(rowItem, rowIndex) in currentTableData.rows"
                  :key="rowIndex"
                >
                  <td>{{ rowItem.label }}</td>

                  <td v-for="(item, index2) in rowItem.values" :key="index2">
                    {{ item }}
                  </td>
                </tr>
              </tbody>
              <caption>
                {{
                  currentTableData.caption
                }}
              </caption>
            </table>
          </div>
          <!-- /.table-responsive -->
        </div>
      </div>
    </section>
    <!-- /.tabbed-tables -->
  </LoadingOverlay>
</template>
<script setup>
import { ref, computed } from 'vue';
import { formatNumber } from '@/services/utils';
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';

const activeTab = ref('reinvestment_tab');

const props = defineProps({
  reinvestedPriceData: {
    type: Object,
    default: () => ({}),
  },
  cashPriceData: {
    type: Object,
    default: () => ({}),
  },
  ticker: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const setActiveTab = (tab) => {
  activeTab.value = tab;
};
const periods = [
  'wk1',
  'mo1',
  'mo3',
  'mo6',
  'qtd',
  'ytd',
  'yr1',
  'yr3',
  'yr5',
  'yr10',
  'yr15',
];
const headers = [
  '',
  '1 Wk',
  '1 Mo',
  '3 Mo',
  '6 Mo',
  'QTD',
  'YTD',
  '1 Yr',
  '3 Yr',
  '5 Yr',
  '10 Yr',
  '15 Yr',
];
const reinvestmentData = computed(() => ({
  title: 'Total Return (%) — With Reinvestment',
  note: 'Periods greater than 1 Year are annualized.',
  headers: headers,
  rows: [
    {
      label: 'Price',
      values: periods.map((period) =>
        formatNumber(
          props.reinvestedPriceData?.[`price_tr_reinvested_${period}`]?.[
            props.ticker
          ],
          2
        )
      ),
    },
    {
      label: 'PEER GROUP (MKT PRICE)',
      values: periods.map((period) =>
        formatNumber(
          props.reinvestedPriceData?.[`price_tr_reinvested_${period}`]?.[
            'average'
          ],
          2
        )
      ),
    },
    {
      label: 'Rank',
      values: periods.map((period) => {
        const rank =
          props.reinvestedPriceData?.[`price_tr_reinvested_${period}_rank`]?.[
            props.ticker
          ];
        const count =
          props.reinvestedPriceData?.[`price_tr_reinvested_${period}_count`]?.[
            props.ticker
          ];
        return rank && count ? `${rank}/${count}` : '-';
      }),
    },
    {
      label: 'NAV',
      values: periods.map((period) =>
        formatNumber(
          props.reinvestedPriceData?.[`nav_tr_reinvested_${period}`]?.[
            props.ticker
          ],
          2
        )
      ),
    },
    {
      label: 'PEER GROUP (NAV)',
      values: periods.map((period) =>
        formatNumber(
          props.reinvestedPriceData?.[`nav_tr_reinvested_${period}`]?.[
            'average'
          ],
          2
        )
      ),
    },
    {
      label: 'Rank',
      values: periods.map((period) => {
        const rank =
          props.reinvestedPriceData?.[`nav_tr_reinvested_${period}_rank`]?.[
            props.ticker
          ];
        const count =
          props.reinvestedPriceData?.[`nav_tr_reinvested_${period}_count`]?.[
            props.ticker
          ];
        return rank && count ? `${rank}/${count}` : '-';
      }),
    },
  ],
  caption:
    'Click on any value in "Peer Group" rows to see full peer group total returns.',
}));

const cashDividendData = computed(() => ({
  title: 'Total Return (%) — Cash Dividends',
  note: 'Periods greater than 1 Year are annualized.',
  headers: headers,
  rows: [
    {
      label: 'Price',
      values: periods.map((period) =>
        formatNumber(
          props.cashPriceData?.[`price_tr_cash_${period}`]?.[props.ticker],
          2
        )
      ),
    },
    {
      label: 'PEER GROUP (MKT PRICE)',
      values: periods.map((period) =>
        formatNumber(
          props.cashPriceData?.[`price_tr_cash_${period}`]?.['average'],
          2
        )
      ),
    },
    {
      label: 'Rank',
      values: periods.map((period) => {
        const rank =
          props.cashPriceData?.[`price_tr_cash_${period}_rank`]?.[props.ticker];
        const count =
          props.cashPriceData?.[`price_tr_cash_${period}_count`]?.[
            props.ticker
          ];
        return rank && count ? `${rank}/${count}` : '-';
      }),
    },
    {
      label: 'NAV',
      values: periods.map((period) =>
        formatNumber(
          props.cashPriceData?.[`nav_tr_cash_${period}`]?.[props.ticker],
          2
        )
      ),
    },
    {
      label: 'PEER GROUP (NAV)',
      values: periods.map((period) =>
        formatNumber(
          props.cashPriceData?.[`nav_tr_cash_${period}`]?.['average'],
          2
        )
      ),
    },
    {
      label: 'Rank',
      values: periods.map((period) => {
        const rank =
          props.cashPriceData?.[`nav_tr_cash_${period}_rank`]?.[props.ticker];
        const count =
          props.cashPriceData?.[`nav_tr_cash_${period}_count`]?.[props.ticker];
        return rank && count ? `${rank}/${count}` : '-';
      }),
    },
  ],
  caption:
    'Click on any value in "Peer Group" rows to see full peer group total returns.',
}));
const currentTableData = computed(() =>
  activeTab.value === 'reinvestment_tab'
    ? reinvestmentData.value
    : cashDividendData.value
);
</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col" v-if="!isShareClassEmpty">
        <section class="hero component with-ad spacing-top-tight is-visible">
          <div>
            <h1 v-if="fundBdc">Business Development Company</h1>
            <h1 v-else>CLOSED-END FUND</h1>
            <ul class="links">
              <li>
                <a href="#">{{ shareClass.fund.group }}</a>
              </li>
              <li>
                <a href="#">{{ shareClass.fund.main_group }}</a>
              </li>
            </ul>
            <h2>
              <b>{{ shareClass.ticker }}</b> – {{ shareClass.fund.name }}
            </h2>
          </div>
          <div>
            <div class="mock-ad-space short-square"><div>Ad Space</div></div>
          </div>
        </section>
        <section class="link-bar component spacing-top-tight is-visible">
          <ul>
            <li>
              <h5>Related Indexes</h5>
            </li>
            <li v-for="(index, idx) in relatedIndexes" :key="idx">
              <a href="#">{{ index.name }}</a>
            </li>
          </ul>
        </section>
      </div>
      <div class="col" v-else>
        <section class="hero component spacing-top-tight is-visible">
          <div>
            <h2>Fund does not found. Please search another ticker</h2>
          </div>
        </section>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container -->
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  shareClass: {
    type: Object,
    default: () => ({}), // Default value as an empty object
  },
  relatedIndexes: {
    type: Object,
    default: () => ({}), // Default value as an empty object
  },
});

const fundBdc = computed(() => props.shareClass?.fund?.is_fund_type_dbc);
const isShareClassEmpty = computed(() => {
  return Object.keys(props.shareClass).length === 0;
});
</script>

<template>
  <div>
    <LoadingOverlay :loading="loading">
      <LineChart :tabs="tabs" yAxisFormat="money" />
    </LoadingOverlay>
  </div>
</template>

<script setup>
import LineChart from '@/components/charts/LineChart.vue';
import { dateToQuarter } from '@/services/utils';
import LoadingOverlay from '@/components/ui/LoadingOverlay.vue';
import { computed } from 'vue';

const props = defineProps({
  uniiShare: {
    type: Object,
    default: () => ({}),
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const tabs = computed(() => [
  {
    title: 'UNII/SHARE',
    labels: props.uniiShare?.date?.map((x) => dateToQuarter(x)) || [],
    datasets: [
      {
        label: 'Fund',
        data: props.uniiShare?.fund_unii,
        borderColor: '#21739E',
        backgroundColor: '#21739E',
        pointStyle: false,
      },

      {
        label: 'Peer Group Avg',
        data: props.uniiShare?.peer_unii,
        borderColor: '#6EF4BB',
        backgroundColor: '#6EF4BB',
        pointStyle: false,
      },
    ],
  },
]);
</script>

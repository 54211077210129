<template>
  <div class="table-responsive">
    <table class="table sidebar">
      <thead>
        <tr>
          <th colspan="2">Volume & Liquidity</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in volumeData" :key="`item_${index}`">
          <th>
            {{ item.label }}
          </th>
          <td>{{ item.value || 0 }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- /.table-responsive -->
</template>

<script setup>
import { computed } from 'vue';
import { formatNumber } from '@/services/utils';

const props = defineProps({
  shareClassData: {
    type: Object,
    default: () => ({}), // Default value as an empty object
  },
});

const volumeData = computed(() => {
  const {
    liquidity_90d,
    volume_avg_30d,
    volume_avg_90d,
    volume_trend,
    volume_trend_comp,
  } = props.shareClassData || {};

  return {
    averageDailyDollar: {
      label: 'Average Daily $ (K)',
      value: liquidity_90d?.data_value
        ? `$${formatNumber(liquidity_90d.data_value)}k`
        : '-',
    },
    avgVolume30D: {
      label: 'Avg Volume 30 D',
      value: volume_avg_30d?.data_value
        ? `${formatNumber(volume_avg_30d.data_value)}`
        : '-',
    },
    avgVolume90D: {
      label: 'Avg Volume 90 D',
      value: volume_avg_90d?.data_value
        ? `${formatNumber(volume_avg_90d.data_value)}`
        : '-',
    },
    volumeTrend: {
      label: 'Volume Trend',
      value: volume_trend?.data_value
        ? `${formatNumber(volume_trend.data_value, 2)}%`
        : '-',
    },
    compVolumeTrend: {
      label: 'Comp Volume Trend',
      value: volume_trend_comp?.data_value
        ? `${formatNumber(volume_trend_comp.data_value, 2)}%`
        : '-',
    },
  };
});
</script>

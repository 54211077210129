import axios from '@/libs/axios';
import { ref } from 'vue';
import { handleApiError } from '@/services/error';

export const useFundProfile = () => {
  const shareClass = ref({ fund: {}, price: { change_from_previous: {} } });
  const shareClassData = ref([]);
  const taxDividend = ref([]);
  const uniiShare = ref([]);
  const earnings = ref([]);
  const relatedIndexes = ref([]);
  const topHoldings = ref([]);
  const growth10k = ref({});
  const priceChartData = ref({});
  const reinvestedPriceData = ref({});
  const cashPriceData = ref({});
  const dividendHistory = ref([]);
  const fundProfileDiscount = ref({});

  // Individual loading states for each API call
  const loadingShareClass = ref(false);
  const loadingShareClassData = ref(false);
  const loadingTaxDividend = ref(false);
  const loadingUniiShare = ref(false);
  const loadingEarnings = ref(false);
  const loadingRelatedIndexes = ref(false);
  const loadingTopHoldings = ref(false);
  const loadingGrowth10k = ref(false);
  const loadingPriceChartData = ref(false);
  const loadingReinvestedPriceData = ref(false);
  const loadingCashPriceData = ref(false);
  const loadingDividendHistory = ref(false);
  const loadingFundProfileDiscount = ref(false);

  const fetchShareClass = async (ticker) => {
    loadingShareClass.value = true;
    try {
      const { data } = await axios.get(`cefdata/shareclass/?tickers=${ticker}`);
      shareClass.value = data.results[0];
    } catch (error) {
      handleApiError(error, 'Error in getting Fund Data');
    } finally {
      loadingShareClass.value = false;
    }
  };

  const fetchShareClassData = async (ticker) => {
    loadingShareClassData.value = true;
    try {
      const { data } = await axios.get(
        `cefdata/shareclassdata/?tickers=${ticker}`
      );
      let res_data = {};
      data.forEach(function (item) {
        res_data[item.data_type] = { ...item };
      });
      shareClassData.value = res_data;
    } catch (error) {
      handleApiError(error, 'Error in getting Fund Data');
    } finally {
      loadingShareClassData.value = false;
    }
  };

  const fetchTaxDividend = async (ticker) => {
    loadingTaxDividend.value = true;
    try {
      const { data } = await axios.get(`cefdata/taxdividend/${ticker}/`);
      taxDividend.value = data;
    } catch (error) {
      handleApiError(error, 'Error in getting Tax Dividend Data');
    } finally {
      loadingTaxDividend.value = false;
    }
  };

  const fetchUniiShare = async (ticker) => {
    loadingUniiShare.value = true;
    try {
      const { data } = await axios.get(`cefdata/unii/${ticker}/`);
      uniiShare.value = data;
    } catch (error) {
      handleApiError(error, 'Error in getting Chart UNII Data');
    } finally {
      loadingUniiShare.value = false;
    }
  };

  const fetchEarnings = async (ticker) => {
    loadingEarnings.value = true;
    try {
      const { data } = await axios.get(`cefdata/earnings/${ticker}/`);
      earnings.value = data;
    } catch (error) {
      handleApiError(error, 'Error in getting Earnings Data');
    } finally {
      loadingEarnings.value = false;
    }
  };

  const fetchRelatedIndexes = async (ticker) => {
    loadingRelatedIndexes.value = true;
    try {
      const { data } = await axios.get(`cefdata/related-indexes/${ticker}/`);
      relatedIndexes.value = data;
    } catch (error) {
      handleApiError(error, 'Error in getting Related Indexes Data');
    } finally {
      loadingRelatedIndexes.value = false;
    }
  };

  const fetchTopHoldings = async (ticker) => {
    loadingTopHoldings.value = true;
    try {
      const { data } = await axios.get(`cefdata/top-holdings/${ticker}/`);
      topHoldings.value = data;
    } catch (error) {
      handleApiError(error, 'Error in getting Top Holdings Data');
    } finally {
      loadingTopHoldings.value = false;
    }
  };

  const fetchGrowth10k = async (ticker) => {
    loadingGrowth10k.value = true;
    try {
      const { data } = await axios.get(`cefdata/growth10k/${ticker}/`);
      growth10k.value = data;
    } catch (error) {
      handleApiError(error, 'Error in getting Chart Growth Data');
    } finally {
      loadingGrowth10k.value = false;
    }
  };

  const fetchPriceChartData = async (ticker) => {
    loadingPriceChartData.value = true;
    try {
      const { data } = await axios.get(`cefdata/price-chart-data/${ticker}/`);
      priceChartData.value = data;
    } catch (error) {
      handleApiError(error, 'Error in getting Chart Price Data');
    } finally {
      loadingPriceChartData.value = false;
    }
  };

  const fetchReinvestedPriceData = async (ticker) => {
    loadingReinvestedPriceData.value = true;

    try {
      const { data } = await axios.get(
        `cefdata/reinvested-price-data/${ticker}/`
      );
      reinvestedPriceData.value = data;
    } catch (error) {
      handleApiError(error, 'Error in getting Chart Reinvested Price Data');
    } finally {
      loadingReinvestedPriceData.value = false;
    }
  };

  const fetchCashPriceData = async (ticker) => {
    loadingCashPriceData.value = true;

    try {
      const { data } = await axios.get(`cefdata/cash-price-data/${ticker}/`);
      cashPriceData.value = data;
    } catch (error) {
      handleApiError(error, 'Error in getting Chart Price Data');
    } finally {
      loadingCashPriceData.value = false;
    }
  };

  const fetchDividendHistory = async (ticker) => {
    loadingDividendHistory.value = true;

    try {
      const { data } = await axios.get(
        `cefdata/dividendhistory/?tickers=${ticker}&range=1yr`
      );
      dividendHistory.value = data;
    } catch (error) {
      handleApiError(error, 'Error in getting Dividend History');
    } finally {
      loadingDividendHistory.value = false;
    }
  };

  const fetchFundProfileDiscount = async (ticker) => {
    loadingFundProfileDiscount.value = true;

    try {
      const { data } = await axios.get(
        `cefdata/fund-profile-discount/${ticker}/`
      );
      fundProfileDiscount.value = data;
    } catch (error) {
      handleApiError(error, 'Error in getting Discount Data');
    } finally {
      loadingFundProfileDiscount.value = false;
    }
  };

  return {
    fetchShareClass,
    fetchShareClassData,
    fetchTaxDividend,
    fetchUniiShare,
    fetchEarnings,
    fetchRelatedIndexes,
    fetchTopHoldings,
    fetchGrowth10k,
    fetchPriceChartData,
    fetchReinvestedPriceData,
    fetchCashPriceData,
    fetchDividendHistory,
    fetchFundProfileDiscount,

    loadingShareClass,
    loadingShareClassData,
    loadingTaxDividend,
    loadingUniiShare,
    loadingEarnings,
    loadingRelatedIndexes,
    loadingTopHoldings,
    loadingGrowth10k,
    loadingPriceChartData,
    loadingReinvestedPriceData,
    loadingCashPriceData,
    loadingDividendHistory,
    loadingFundProfileDiscount,

    shareClass,
    shareClassData,
    taxDividend,
    uniiShare,
    earnings,
    relatedIndexes,
    topHoldings,
    growth10k,
    priceChartData,
    reinvestedPriceData,
    cashPriceData,
    dividendHistory,
    fundProfileDiscount,
  };
};
